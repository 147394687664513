.App {
  text-align: left;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  padding-top: 30px;
  min-height: 16vh;
  width: 170px;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
}

.container {
  position: relative;
}

.container .play-icon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

svg:hover #play-svg {
  fill: #cc181e;
}

@font-face {
  font-family: 'Helvetica Neue';
  font-weight: normal;
  src: url(./fonts/HelveticaNeue-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-weight: 500;
  src: url(./fonts/HelveticaNeue-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-weight: bold;
  src: url(./fonts/HelveticaNeue-Bold.ttf) format('truetype');
}

.hover-underline:hover {
  text-decoration: underline !important;
}

.rpanel {
  width: calc(100% + 80px);
  margin-left: -40px;
  margin-right: -40px;
}

@media (min-width: 900px) {
  .rpanel {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}
